<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:57
-->
<template>
	<div class="starVolunteerDetail">
		<common-title :title="'星级志愿者详情'" />
		<div class="starVolunteerDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">姓名</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.name" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">学号</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.studentId" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">性别</span>
						<span class="common-table-text"><span>{{starVolunteerDetail.gender == true?"男":"女"}}</span></span>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">总工时</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.workingHours" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">学院</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.collegeName" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">班级</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.faculty" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">年份</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.reviewYear" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">提交时间</span>
						<el-input readonly="readonly" v-model="starVolunteerDetail.createTime" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">志愿服务心得</span>
						<el-input readonly="readonly" type="textarea" v-model="starVolunteerDetail.experience"
							placeholder="请输入" class="common-table-val" />
					</div>
				</div>
			</div>
			<div class="">
				<div class="starVolunteerDetail-title">
					工时表
				</div>
				<el-table :data="starVolunteerDetail.workingHoursList" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="workingHours" label="工时数">
					</el-table-column>
					<el-table-column prop="activityName" label="活动名称">
						<!-- <template slot-scope="scope">
							<span>暂无参数</span>
						</template> -->
					</el-table-column>
					<el-table-column prop="activityTime" label="活动时间">
						<!-- <template slot-scope="scope">
							<span v-for="(item, index) in scope.row.activityTime">{{item.startTime}}~{{item.endTime}}</span>
						</template> -->
					</el-table-column>
				</el-table>
			</div>
			<div class="">
				<div class="starVolunteerDetail-title">
					历史评定
				</div>
				<div v-for="(item, index) in starVolunteerDetail.starRecordSheetList" :key="index" class="common-table">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">年份</span>
							<el-input readonly="readonly" v-model="item.reviewYear" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div v-if="item.status == 1" class="common-table-col">
							<span class="common-table-label">星级评定</span>
							<span class="common-table-text">
								<i v-for="i in item.star" :key="i" class="iconfont icon-wujiaoxing"></i>
							</span>
						</div>
						<div v-if="item.status == 2" class="common-table-col">
							<span class="common-table-label">拒绝原因</span>
							<span class="common-table-text">
								{{item.reason}}
							</span>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">审核人</span>
							<el-input readonly="readonly" v-model="item.reviewPho" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">通过时间</span>
							<el-input v-model="item.updateTime" placeholder="请输入" class="common-table-val" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		detailStarVolunteer,
	} from "@/api/volunteer";
	export default {
		data() {
			return {
				starVolunteerDetail: {
					status: 1
				},
				tableData: [],
				detailId: "",
			};
		},
		watch: {},
		mounted() {
			this.detailId = this.$route.query.detailId
			this.getStarVolunteerDetail()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			getStarVolunteerDetail() {
				detailStarVolunteer(this.detailId, {
					id: this.detailId
				}).then((res) => {
					if (res.code == 0) {
						this.starVolunteerDetail = res.data
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.starVolunteerDetail-btn .el-button--default {
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	/deep/.el-textarea__inner {
		resize: none;
		height: 72px;
		border: 0;
	}

	.starVolunteerDetail {
		.starVolunteerDetail-dia-formitem {
			margin: 20px 20px 0 0;

			.starVolunteerDetail-dia-radio {
				margin: 12px 0 10px 0;
			}

			.starVolunteerDetail-dia-ipt {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}

			.starVolunteerDetail-dia-select {
				border: 1px solid #DCDFE6;
				width: 398px;
			}

			.starVolunteerDetail-dia-textarea {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}
		}

		.starVolunteerDetail-content {
			padding: 0 20px 20px 20px;

			.starVolunteerDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold
				;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
			}

			.starVolunteerDetail-btn {
				display: flex;
				margin-top: 20px;
			}
		}
	}
</style>
